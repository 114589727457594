import { Box, Button, Grid, IconButton, Link, List, ListItem, Stack, Typography } from "@mui/material";
import {
    StyledMobileNavigation,
    mobileNavigationClasses as classes,
    StyledSwipeableDrawer,
} from "./StyledMobileNavigation";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext, useState } from "react";
import { customNavigate, links, NavigationRoutes, pages } from "../../routes";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
import solarEstateLogo from "./icons/solar-estate-logo.svg";
import { UsersButtonsSection } from "../UsersButtonsSection/UsersButtonsSection";
import nextGenEU from "./../Footer/assets/NextGenerationEU.png";

export const MobileNavigation = () => {
    let navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useContext(GlobalContext);

    return (
        <Grid>
            <StyledMobileNavigation className={classes.root}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    className={classes.menuButton}
                    onClick={() => {
                        setIsOpen(true);
                    }}>
                    <MenuIcon />
                </IconButton>
            </StyledMobileNavigation>
            <StyledSwipeableDrawer
                anchor={"left"}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                onOpen={() => {
                    setIsOpen(true);
                }}>
                <img className={classes.logo} src={solarEstateLogo} alt={"Solar Estate Logo"} />
                <Grid container className={classes.container} flexDirection={"column"} alignItems={"center"}>
                    <Box className={classes.box}>
                        {pages.map((page, index) => (
                            <Button
                                key={page}
                                className={classes.navigation}
                                onClick={() => {
                                    customNavigate(navigate, links[index]);
                                }}>
                                <Typography className={classes.pageLinkText}> {page} </Typography>
                            </Button>
                        ))}
                    </Box>
                    <Box className={classes.box} alignItems={"center"} margin={"10px"}>
                        <Link href={"/Декларация за поверителност.pdf"} className={classes.link}>
                            Декларация за поверителност
                        </Link>
                        <Link href={"/Общи условия-3.pdf"} className={classes.link}>
                            Общи условия
                        </Link>
                        <Link
                            onClick={() => {
                                navigate(`/${NavigationRoutes.CookiesPolicy}`);
                            }}
                            className={classes.link}>
                            Политика за бисквитки
                        </Link>
                    </Box>
                    <UsersButtonsSection />
                    <Stack className={classes.nextGenContainer}>
                        <img width={"200px"} height={"50px"} src={nextGenEU} alt="Next Generation EU" />
                        <Typography variant={"caption"} className={classes.nextGenText} lineHeight={"16px"}>
                            <b>Проект:</b> Решения в областта на информационните и комуникационни технологии и
                            киберсигурността в малките и средните предприятия, Оперативна програма 2021BG-RRP-
                            Национален план за възстановяване и устойчивост.
                        </Typography>
                        <Typography variant={"caption"} className={classes.nextGenText} lineHeight={"16px"}>
                            <b>Договор:BG-RRP-3.005-6156-C01</b>
                        </Typography>
                        <Typography variant={"caption"} className={classes.nextGenText} lineHeight={"16px"}>
                            <b>Бенефициент: “ ГОФРИЛО КО“ ЕООД</b>
                        </Typography>
                        <Typography variant={"caption"} className={classes.nextGenText} lineHeight={"16px"}>
                            <b>Обща стойност: 15 600,00 лв.</b> “ Финансирано от Европейския съюз – NextGenerationEU “
                        </Typography>
                        <Typography variant={"caption"} className={classes.nextGenText} lineHeight={"16px"}>
                            <b>Начало: 24.07.2023г.</b>
                        </Typography>
                        <Typography variant={"caption"} className={classes.nextGenText} lineHeight={"16px"}>
                            <b>Край: 24.07.2024г.</b>
                        </Typography>
                    </Stack>
                </Grid>
            </StyledSwipeableDrawer>
        </Grid>
    );
};
