import React from "react";
import { footerClasses as classes, StyledFooter } from "./StyledFooter";
import { Container, Grid, Link, List, ListItem, Stack } from "@mui/material";
import { Link as NavLink, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { links, NavigationRoutes, supportLinks } from "../../routes";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import solarEstates from "./assets/solar-estates.svg";
import nextGenEU from "./assets/NextGenerationEU.png";

export const Footer = () => {
    const navigate = useNavigate();
    return (
        <StyledFooter className={classes.root}>
            <Container fixed className={classes.container}>
                <Grid container className={classes.topContainer} justifyContent={"space-between"}>
                    <Grid container className={classes.topContainerColumn}>
                        <Grid container flexDirection={"column"}>
                            <Grid item>
                                <img src={solarEstates} alt="Solar Estates" className={classes.logo} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.description}>
                                    Свързаме инвеститори във ВЕИ с продавачи или отдаващи под наем подходящи имоти.
                                    Освен сред подбрани имоти, инвеститорите могат също да избират и проверени
                                    изпълнители на проектите им.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container flexDirection={"column"}>
                            <Grid item>
                                <Typography className={classes.address}> Адрес: Черни връх 47, ет. 5</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.email}>office@vei-imot.bg</Typography>
                            </Grid>
                            <Grid item>
                                <Link href="https://www.facebook.com/veiimot/" className={classes.iconLink}>
                                    <FacebookIcon className={classes.icon} />
                                </Link>
                                <Link href="https://www.linkedin.com/company/84372699" className={classes.iconLink}>
                                    <LinkedInIcon className={classes.icon} />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.topContainerColumn}>
                        <List>
                            <ListItem>
                                <Typography className={classes.columnHeading}>Правила и условия</Typography>
                            </ListItem>
                            <ListItem key={"Декларация за поверителност"}>
                                <Link href={"/Декларация за поверителност Gofrilo.pdf"} className={classes.columnText}>
                                    Декларация за поверителност
                                </Link>
                            </ListItem>
                            <ListItem key={"Общи условия"}>
                                <Link href={"/Общи условия-3 Goffrilo.pdf"} className={classes.columnText}>
                                    Общи условия
                                </Link>
                            </ListItem>
                            <ListItem key={"Политика за бисквитки"}>
                                <Link
                                    onClick={() => {
                                        navigate(`/${NavigationRoutes.CookiesPolicy}`);
                                    }}
                                    className={classes.columnText}>
                                    Политика за бисквитки
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid container className={classes.topContainerColumn}>
                        <List>
                            <ListItem>
                                <Typography className={classes.columnHeading}>Полезни линкове</Typography>
                            </ListItem>
                            {supportLinks.map((text: string, index) => (
                                <ListItem key={text}>
                                    <NavLink to={`/${links[index]}/`} className={classes.columnText}>
                                        {text}
                                    </NavLink>
                                </ListItem>
                            ))}
                            <ListItem key={"Контакт"}>
                                <Link href={"mailto:office@vei-imot.bg"} className={classes.columnText}>
                                    Контакт
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Stack width={"500px"} gap={"8px"}>
                        <img width={"200px"} height={"50px"} src={nextGenEU} alt="Next Generation EU" />
                        <Typography variant={"caption"} className={classes.columnText} lineHeight={"16px"}>
                            <b>Проект:</b> Решения в областта на информационните и комуникационни технологии и
                            киберсигурността в малките и средните предприятия, Оперативна програма 2021BG-RRP-
                            Национален план за възстановяване и устойчивост.
                        </Typography>
                        <Typography variant={"caption"} className={classes.columnText} lineHeight={"16px"}>
                            <b>Договор:BG-RRP-3.005-6156-C01</b>
                        </Typography>
                        <Typography variant={"caption"} className={classes.columnText} lineHeight={"16px"}>
                            <b>Бенефициент: “ ГОФРИЛО КО“ ЕООД</b>
                        </Typography>
                        <Typography variant={"caption"} className={classes.columnText} lineHeight={"16px"}>
                            <b>Обща стойност: 15 600,00 лв.</b> “ Финансирано от Европейския съюз – NextGenerationEU “
                        </Typography>
                        <Typography variant={"caption"} className={classes.columnText} lineHeight={"16px"}>
                            <b>Начало: 24.07.2023г.</b>
                        </Typography>
                        <Typography variant={"caption"} className={classes.columnText} lineHeight={"16px"}>
                            <b>Край: 24.07.2024г.</b>
                        </Typography>
                    </Stack>
                </Grid>
            </Container>
            <Grid container className={classes.bottomWrapper}>
                <Container maxWidth={"lg"}>
                    <Grid container className={classes.bottomContainer}>
                        <Grid item>
                            <Typography variant={"h6"} component={"div"} className={classes.bottomContainerText}>
                                Solar Estates All right reserved
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </StyledFooter>
    );
};
